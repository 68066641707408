import React, { useCallback, useContext, useEffect, useRef } from 'react'
import { Controller, FormProvider, useForm, useWatch } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { getPropertyValue } from 'utils/validation'
import { BookerContext, BookerFormValues, Destination } from '../Booker'
import { DateRangeSelector } from '../Booker/components/DateRangeSelector'
import FieldButton from '../Booker/components/FieldButton'
import FieldContainer from '../Booker/components/FieldContainer'
import HotelSelector, { HotelBookerType } from '../Booker/components/HotelSelector'
import { PromoCodeSelector } from '../Booker/components/PromoCodeSelector'
import RoomsSelector, { RoomFormType } from '../Booker/components/RoomsSelector'
import moment, { Moment } from 'moment'
import PromotionCodeField from '../Booker/components/PromotionCodeField'
import AutoCompleteSelector from '../Booker/components/AutoCompleteSelector'
import debounce from 'lodash/debounce'
import cloneDeep from 'lodash.clonedeep'
import { getTotalAdults, getTotalChildren } from '../../utils/bookerUtils'
import classNames from 'classnames'
import { faUsd } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useTransition from '../../hooks/useTransition'
import LoyaltyPlanSelector from '../Booker/components/LoyaltyPlanSelector'

export type HotelFormType = {
   destination?: {
      destinationType: 'HOTEL' | 'DESTINATION'
      id: number
   } | null
   startDate?: string | null
   endDate?: string | null
   rooms: Array<RoomFormType>
   maxAdults: number
   maxRooms: number
   maxChildren: number
   minChildrenAge: number
   maxChildrenAge: number
   quoteRates: boolean
   hasMultiDestination: boolean
   showChains?: boolean
   promoCodeInline?: boolean
   promoCodeInlineIcon?: boolean
}

export interface HotelBookerRatesDate {
   amount: number
   lowestRate: boolean
   available: boolean
   isAvailable: boolean
   isLowestRate: boolean
}

export type HotelBookerRates = {
   months: Array<string>
   days: {
      [date: string]: HotelBookerRatesDate
   }
   lastProcessedDate?: string
   currency?: string
}

export type ChangeRatesOnClickType = {
   range?: {
      startDate: string
      endDate: string
   }
   adults: number
   children: number
   destination: Destination
}

export type LoyaltyPlanListItem = {
   hasPointBenefit: boolean
   id: number
   name: string
   order: number
   promotionCodes: Array<LoyaltyPlanListItemPromoCodes>
}
export type LoyaltyPlanListItemPromoCodes = {
   id: number
   name: string
   promotionCode: string
   maxStayDays: number | null
   allowPointRedemption: boolean
   allowUpgrade: boolean
   pmsCode: string
   pmsMessage: string
   promotionType: string
   discountPercentage: number
   isLoyaltySpecific: boolean
   isActive: boolean
}
export type LoyaltyPlanList = {
   isPristine: boolean
   isFetching: boolean
   data: Array<LoyaltyPlanListItem> | null
}
type Props = {
   hotelList: Array<HotelBookerType>
   onChangeRates?: Function
   onSubmit: (data: BookerFormValues, submitUrl?: string) => void
   rates?: HotelBookerRates
   hotelForm: HotelFormType
   request?: BookerFormValues
   loyaltyPlanList?: LoyaltyPlanList
   onChangeLoyaltyPlan?: Function
}

export const HotelForm = ({
   hotelList,
   onSubmit,
   hotelForm,
   request,
   onChangeRates,
   rates,
   loyaltyPlanList,
   onChangeLoyaltyPlan,
}: Props) => {
   const {
      locale,
      dateFormat,
      isMobile,
      hideIcon,
      inline,
      isAutocomplete,
      numberOfMonths,
      vertical,
      useTailwindPrefix,
      enableOutsideDays,
   } = useContext(BookerContext)
   const intl = useIntl()
   const showHotels = hotelList?.length > 1
   const loyaltyPlanSelector = loyaltyPlanList?.data && loyaltyPlanList?.data?.length > 0 && onChangeLoyaltyPlan
   const getDefaultValues = useCallback(
      () => ({
         destination: request?.destination,
         rooms:
            request && request.rooms
               ? request.rooms
               : [
                    {
                       adults: 2,
                       children: 0,
                       childrenAges: [],
                    },
                 ],
         tripDates: request?.tripDates,
         promotionCode: request?.promotionCode,
         eventCode: request?.eventCode,
         ...(request?.loyaltyPlan && { loyaltyPlan: request.loyaltyPlan }),
         ...(request?.channelId && { channelId: request.channelId }),
      }),
      [request, request?.loyaltyPlan],
   )
   const methods = useForm<BookerFormValues>({
      defaultValues: getDefaultValues(),
   })
   // se resetea solo cuando el formulario cambia de null a algo.
   // Esto es para evitar que se resetee cuando se cargan los rates
   // Esto se debe ejecutar cuando hay un cambio en el request que no es hecho por el flujo normal (como aplicar una promo a través de un popup)
   useTransition(
      (prevRequest: BookerFormValues | undefined) => {
         // console.log(prevRequest, request)
         if (
            (prevRequest && prevRequest.promotionCode !== request?.promotionCode) ||
            ((!prevRequest || !prevRequest.tripDates) && request?.tripDates) ||
            (prevRequest?.tripDates &&
               request?.tripDates &&
               prevRequest.tripDates.startDate !== request.tripDates.startDate &&
               prevRequest.tripDates.endDate !== request.tripDates.endDate) ||
            ((!prevRequest || !prevRequest.loyaltyPlan) && request?.loyaltyPlan) ||
            (prevRequest?.loyaltyPlan &&
               request?.loyaltyPlan &&
               prevRequest.loyaltyPlan.loyaltyPlanId !== request.loyaltyPlan.loyaltyPlanId &&
               prevRequest.loyaltyPlan.promotionCode !== request.loyaltyPlan.promotionCode)
         ) {
            methods.reset(getDefaultValues())
         }
      },
      [request],
   )

   const {
      formState: { errors },
   } = methods

   const onSubmitEvent = (data: BookerFormValues) => {
      let submitUrl: string | undefined
      if (data.destination?.destinationType === 'hotel') {
         submitUrl = hotelList.find((hotel) => hotel.id === data.destination?.id)?.alternativePath
         if (submitUrl) {
            submitUrl = submitUrl
               .replace('{startDate}', data.tripDates?.startDate || '')
               .replace('{endDate}', data.tripDates?.endDate || '')
               .replace('{adults}', data.rooms ? data.rooms.map((r) => r.adults).join(',') : '')
               .replace('{rooms}', data.rooms ? data.rooms.length.toString() : '')
            if (data.rooms && data.rooms.reduce((acc, r) => acc + r.children, 0) > 0) {
               const childrenAges = data.rooms
                  .map((r) => (r.childrenAges ? r.childrenAges.map((c) => c.age).join('|') : ''))
                  .join(',')
               submitUrl = submitUrl
                  .replace('{children}', data.rooms.map((r) => r.children).join(','))
                  .replace('{childrenAges}', childrenAges)
            } else {
               submitUrl = submitUrl?.replace('{children}', '0').replace(/&(\w|\.|\-|\[|\])+={childrenAges}/, '')
            }
         }
      }

      onSubmit && onSubmit(data, submitUrl)
   }

   const getRatesDebounce = debounce(function (dates?: { startDate: string; endDate: string }) {
      const lastProcessedDate = rates?.lastProcessedDate || moment().add(6, 'months')
      const values = methods.getValues()
      hotelForm.quoteRates &&
         (dates == null || moment(dates.startDate).isBefore(lastProcessedDate)) &&
         onChangeRates &&
         values.rooms &&
         values.destination &&
         onChangeRates({
            range: dates,
            adults: getTotalAdults(values.rooms),
            children: getTotalChildren(values.rooms),
            destination: values.destination,
         } as ChangeRatesOnClickType)
   }, 393)

   const onChangeMonthClick = (day: Moment) => {
      getRatesDebounce({
         startDate: cloneDeep(day).format('YYYY-MM-DD'),
         endDate: cloneDeep(day).add(3, 'months').date(0).format('YYYY-MM-DD'),
      })
   }

   const renderCalendarInfo = () => {
      return (
         <div className="info-panel">
            <div className="itm-info-panel-symbols">
               <div className="itm-info-panel-symbol">
                  <small className="itm-info-panel-symbol-icon itm-info-panel-symbol-icon--lowestRate">
                     <FontAwesomeIcon icon={faUsd} />
                  </small>
                  {intl.formatMessage({ id: 'booker.rate.simbols.lowest' })}
               </div>
               <div className="itm-info-panel-symbol">
                  <small className="itm-info-panel-symbol-icon">--</small>
                  {intl.formatMessage({ id: 'booker.rate.simbols.notavailable' })}
               </div>
            </div>
            {rates?.currency && (
               <div className="itm-info-panel-legend">
                  {intl.formatMessage({ id: 'booker.lowest.rate2' }, { currency: rates.currency })}
               </div>
            )}
         </div>
      )
   }
   return (
      <FormProvider {...methods}>
         <form onSubmit={methods.handleSubmit(onSubmitEvent)}>
            <div
               className={classNames({
                  "before:content-[' '] before:table after:block after:content-[''] after:clear-both":
                     !useTailwindPrefix,
                  "before:tw-content-[' '] before:tw-table after:tw-block after:tw-content-[''] after:tw-clear-both":
                     useTailwindPrefix,
               })}
            >
               <div
                  className={classNames({
                     'flex flex-col min-w-[250px]': !useTailwindPrefix,
                     'tw-flex tw-flex-col tw-min-w-[250px]': useTailwindPrefix,
                     'md:flex-row': !vertical && !useTailwindPrefix,
                     'md:tw-flex-row': !vertical && useTailwindPrefix,
                  })}
               >
                  {loyaltyPlanSelector && (
                     <FieldContainer
                        className={classNames({
                           'md:w-3/12': !vertical && !useTailwindPrefix,
                           'md:tw-w-3/12': !vertical && useTailwindPrefix,
                        })}
                        clipMargin
                     >
                        <Controller
                           name="loyaltyPlan"
                           key={`loyaltyPlan-`}
                           render={({ field: { value, onChange } }) => {
                              function onChangeEvent(
                                 loyaltyPlan?: { promotionCode?: string; loyaltyPlanId: number },
                                 isDeletePromoCode?: boolean,
                              ) {
                                 onChange(loyaltyPlan)
                                 if (onChangeLoyaltyPlan) {
                                    onChangeLoyaltyPlan(loyaltyPlan, isDeletePromoCode)
                                 }
                              }
                              function deletePlan() {
                                 methods.setValue('promotionCode', '')
                                 onChangeEvent(undefined, true)
                              }
                              return (
                                 <LoyaltyPlanSelector
                                    value={value}
                                    onSelect={onChangeEvent}
                                    onDelete={deletePlan}
                                    loyaltyPlanList={loyaltyPlanList}
                                 />
                              )
                           }}
                        />
                     </FieldContainer>
                  )}
                  {showHotels && (
                     <FieldContainer
                        className={classNames({
                           'md:w-3/12': !inline && !vertical && !useTailwindPrefix,
                           'md:tw-w-3/12': !inline && !vertical && useTailwindPrefix,
                           'md:w-1/5': inline && !vertical && !useTailwindPrefix,
                           'md:tw-w-1/5': inline && !vertical && useTailwindPrefix,
                        })}
                        clipMargin
                     >
                        <Controller
                           name="destination"
                           rules={{ required: true }}
                           render={({ field: { value, onChange } }) => {
                              function onChangeEvent(data: any) {
                                 getRatesDebounce()
                                 onChange(data)
                              }
                              return isAutocomplete ? (
                                 <AutoCompleteSelector
                                    selection={value}
                                    onSelect={onChangeEvent}
                                    hotelList={hotelList}
                                    myErrors={getPropertyValue(errors, 'destination')}
                                    vertical={vertical}
                                 />
                              ) : (
                                 <HotelSelector
                                    selection={value}
                                    hotelList={hotelList}
                                    onSelect={onChangeEvent}
                                    myErrors={getPropertyValue(errors, 'destination')}
                                    hasMultiDestination={hotelForm.hasMultiDestination}
                                    showChains={hotelForm.showChains}
                                 />
                              )
                           }}
                        />
                     </FieldContainer>
                  )}
                  <FieldContainer
                     className={classNames({
                        'md:w-4/12': !showHotels && !vertical && !useTailwindPrefix,
                        'md:tw-w-4/12': !showHotels && !vertical && useTailwindPrefix,
                        'md:w-3/12': !inline && showHotels && !vertical && !useTailwindPrefix,
                        'md:tw-w-3/12': !inline && showHotels && !vertical && useTailwindPrefix,
                        'md:w-1/5': inline && showHotels && !vertical && !useTailwindPrefix,
                        'md:tw-w-1/5': inline && showHotels && !vertical && useTailwindPrefix,
                     })}
                     clipMargin
                  >
                     <RoomsSelector
                        minRooms={1}
                        maxRooms={hotelForm.maxRooms}
                        minAdults={1}
                        maxAdults={hotelForm.maxAdults}
                        minChildren={0}
                        maxChildren={hotelForm.maxChildren}
                        minChildrenAge={hotelForm.minChildrenAge}
                        maxChildrenAge={hotelForm.maxChildrenAge}
                     />
                  </FieldContainer>
                  <FieldContainer
                     className={classNames({
                        'md:w-4/12': !showHotels && !vertical && !useTailwindPrefix,
                        'md:tw-w-4/12': !showHotels && !vertical && useTailwindPrefix,
                        'md:w-3/12': !inline && showHotels && !vertical && !useTailwindPrefix,
                        'md:tw-w-3/12': !inline && showHotels && !vertical && useTailwindPrefix,
                        'md:w-[24%]': inline && showHotels && !vertical && !useTailwindPrefix,
                        'md:tw-w-[24%]': inline && showHotels && !vertical && useTailwindPrefix,
                     })}
                     clipMargin
                  >
                     <Controller
                        name="tripDates"
                        rules={{
                           required: intl.formatMessage({ id: 'booker.errors.dates' }),
                           validate: {
                              emptyStart: (v) => {
                                 return (
                                    (v && v.startDate && v.startDate !== '') ||
                                    intl.formatMessage({ id: `booker.errors.select.arrival` })
                                 )
                              },
                              emtpyEnd: (v) =>
                                 (v && v.endDate && v.endDate !== '') ||
                                 intl.formatMessage({ id: `booker.errors.select.departure` }),
                           },
                        }}
                        render={({ field: { value, onChange } }) => (
                           <DateRangeSelector
                              id="tripDates"
                              value={value}
                              onChange={onChange}
                              locale={locale}
                              rates={rates}
                              dateRangePickerProps={{
                                 startDatePlaceholderText: intl.formatMessage({ id: 'booker.checkIn' }),
                                 endDatePlaceholderText: intl.formatMessage({ id: 'booker.checkOut' }),
                                 displayFormat: dateFormat,
                                 minimumNights: 1,
                                 showClearDates: true,
                                 showDefaultInputIcon: !hideIcon,
                                 enableOutsideDays,
                                 ...(hotelForm.quoteRates && {
                                    onNextMonthClick: onChangeMonthClick,
                                    onPrevMonthClick: onChangeMonthClick,
                                    renderCalendarInfo: rates?.currency ? renderCalendarInfo : undefined,
                                 }),
                              }}
                              myErrors={getPropertyValue(errors, 'tripDates')}
                              isMobile={isMobile}
                              numberOfMonths={numberOfMonths}
                              vertical={vertical}
                              useTailwindPrefix={useTailwindPrefix}
                           />
                        )}
                     />
                  </FieldContainer>
                  <FieldContainer
                     className={classNames({
                        'md:w-1/5': inline && showHotels && !vertical && !useTailwindPrefix,
                        'md:tw-w-1/5': inline && showHotels && !vertical && useTailwindPrefix,
                        'md:w-4/12': inline && !(showHotels || vertical) && !useTailwindPrefix,
                        'md:tw-w-4/12': inline && !(showHotels || vertical) && useTailwindPrefix,
                        'mt-3 md:hidden': !inline && !useTailwindPrefix,
                        'tw-mt-3 md:tw-hidden': !inline && useTailwindPrefix,
                     })}
                     clipMargin
                  >
                     {inline ? <PromotionCodeField /> : <PromoCodeSelector id="promotionCodeA" />}
                  </FieldContainer>
                  <FieldContainer
                     className={classNames({
                        'md:w-4/12': !showHotels && !vertical && !useTailwindPrefix,
                        'md:tw-w-4/12': !showHotels && !vertical && useTailwindPrefix,
                        'md:w-3/12': !inline && showHotels && !vertical && !useTailwindPrefix,
                        'md:tw-w-3/12': !inline && showHotels && !vertical && useTailwindPrefix,
                        'md:w-[16%]': inline && showHotels && !vertical && !useTailwindPrefix,
                        'md:tw-w-[16%]': inline && showHotels && !vertical && useTailwindPrefix,
                     })}
                  >
                     <FieldButton type="submit" vertical={vertical}>
                        {intl.formatMessage({ id: 'booker.show.availability' })}
                     </FieldButton>
                  </FieldContainer>
               </div>
               {!inline ? (
                  <div
                     className={classNames({
                        'hidden md:block float-right py-3': !useTailwindPrefix,
                        'tw-hidden md:tw-block tw-float-right tw-py-3': useTailwindPrefix,
                     })}
                  >
                     <PromoCodeSelector />
                  </div>
               ) : (
                  <div className="md:h-8"></div>
               )}
            </div>
         </form>
      </FormProvider>
   )
}
