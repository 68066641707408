import { faMapPin, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useContext, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { OverlayTrigger } from 'ui'
import Field from './Field'
import classNames from 'classnames'
import { BookerContext } from '../Booker'

export type Origin = {
   code: string
   displayText: string
}

type Props = {
   value?: Origin
   onChange: Function
   onClear: Function
   myErrors: any
   isMobile: boolean
   fetchUrl?: string
   useAlgolia?: boolean
}

const OriginField = ({ value, onChange, onClear, myErrors, isMobile, fetchUrl, useAlgolia }: Props) => {
   const [isOpen, setIsOpen] = useState(false)
   const [origins, setOrigins] = useState([] as Array<Origin>)
   const intl = useIntl()
   const { vertical, useTailwindPrefix } = useContext(BookerContext)

   const [text, setText] = useState(value?.displayText || '')

   useEffect(() => {
      if (value && value.code !== '' && (!value.displayText || value.displayText === '')) {
         fetchOrigins(value.code).then((result) => {
            if (result.length > 0) {
               setText(result[0].displayText)
            }
         })
      }
   }, [value])

   function onToggle(data: boolean) {
      setIsOpen(data)
      if (data && (isMobile || origins.length > 0)) {
         if (document && document.body) {
            let orig = document.body.className
            if (!useTailwindPrefix) {
               if (orig.indexOf('fixed md:relative') < 0) {
                  document.body.className = orig + (orig ? ' ' : '') + 'fixed md:relative'
               }
            } else {
               if (orig.indexOf('tw-fixed md:tw-relative') < 0) {
                  document.body.className = orig + (orig ? ' ' : '') + 'tw-fixed md:tw-relative'
               }
            }
         }
      } else {
         if (document && document.body) {
            if (!useTailwindPrefix) {
               document.body.className = document.body.className.replace(/ ?fixed md:relative/, '')
            } else {
               document.body.className = document.body.className.replace(/ ?tw-fixed md:tw-relative/, '')
            }
         }
      }
   }

   const fetchOrigins = async (query: string): Promise<Array<Origin>> => {
      try {
         const response = await fetch(
            `${fetchUrl || process.env.NEXT_PUBLIC_AIRPORTS}?query=${query.trim()}${
               useAlgolia ? '&forceAlgolia=true' : ''
            }`,
            {},
         )
         if (response.ok) {
            const data = await response.json()
            return data.map((r: any) => ({ id: r.Id, code: r.Code, displayText: r.DisplayText } as Origin)).slice(0, 8)
         } else {
            throw new Error()
         }
      } catch (err) {
         return []
      }
   }

   const onInputChange = (e: React.FormEvent<HTMLInputElement>) => {
      setText(e.currentTarget.value)
   }

   function closeSelector() {
      onToggle(false)
      setOrigins([])
   }

   const renderOptions = (item: Origin) => {
      const setOption = () => {
         onChange(item)
         setText(item.displayText)
         closeSelector()
      }
      return (
         <div key={`origin-${item.code}`} onClick={setOption}>
            <a
               className={classNames({
                  'block py-3 px-5 whitespace-nowrap overflow-hidden text-ellipsis hover:bg-gray-100 cursor-pointer text-base text-gray-500':
                     !useTailwindPrefix,
                  'tw-block tw-py-3 tw-px-5 tw-whitespace-nowrap tw-overflow-hidden tw-text-ellipsis hover:tw-bg-gray-100 tw-cursor-pointer tw-text-base tw-text-gray-500':
                     useTailwindPrefix,
               })}
            >
               {item.displayText}
            </a>
         </div>
      )
   }
   const onInputKeyUp = (e: React.FormEvent<HTMLInputElement>) => {
      if (text.trim().length >= 3 && (!value || value.displayText !== text)) {
         setTimeout(() => {
            fetchOrigins(text).then((result) => {
               setOrigins(result)
            })
         }, 500)
      } else {
         setOrigins([])
      }
   }
   const clearText = () => {
      setText('')
      onClear()
   }
   return (
      <OverlayTrigger isOpen={isOpen && (isMobile || origins.length > 0)} onToggle={onToggle}>
         <OverlayTrigger.Trigger
            componentTag="div"
            className={classNames({
               'mb-5': myErrors && !useTailwindPrefix,
               'tw-mb-5': myErrors && useTailwindPrefix,
               'md:mb-0': myErrors && !vertical && !useTailwindPrefix,
               'md:tw-mb-0': myErrors && !vertical && useTailwindPrefix,
            })}
         >
            <Field {...(myErrors && { hasError: true })}>
               <div
                  className={classNames({
                     'w-4 flex justify-center': !useTailwindPrefix,
                     'tw-w-4 tw-flex tw-justify-center': useTailwindPrefix,
                  })}
               >
                  <FontAwesomeIcon icon={faMapPin} />
               </div>
               <div
                  className={classNames({
                     'whitespace-nowrap w-full': !useTailwindPrefix,
                     'tw-whitespace-nowrap tw-w-full': useTailwindPrefix,
                  })}
               >
                  <input
                     className={classNames({
                        'outline-0 w-full text-ellipsis text-[100%]': !useTailwindPrefix,
                        'tw-outline-0 tw-w-full tw-text-ellipsis tw-text-[100%]': useTailwindPrefix,
                     })}
                     onChange={onInputChange}
                     value={text}
                     type={'text'}
                     placeholder={intl.formatMessage({ id: 'booker.origin' })}
                     onKeyUp={onInputKeyUp}
                  />
               </div>
               {text?.length > 0 && <FontAwesomeIcon icon={faTimes} onClick={clearText} color="#82888a" />}
            </Field>
            {myErrors && (
               <div
                  className={classNames({
                     'text-red-400 mt-1 text-sm truncate w-full absolute': !useTailwindPrefix,
                     'tw-text-red-400 tw-mt-1 tw-text-sm tw-truncate tw-w-full tw-absolute': useTailwindPrefix,
                  })}
               >
                  {myErrors.message || intl.formatMessage({ id: 'booker.errors.origin' })}
               </div>
            )}
         </OverlayTrigger.Trigger>
         <OverlayTrigger.Overlay
            className={classNames({
               'bg-white md:border border-gray-200 z-10 fixed inset-0 md:inset-auto md:shadow-lg md:absolute md:mt-0.5 md:min-w-[280px]':
                  !useTailwindPrefix,
               'tw-bg-white md:tw-border tw-border-gray-200 tw-z-10 tw-fixed tw-inset-0 md:tw-inset-auto md:tw-shadow-lg md:tw-absolute md:tw-mt-0.5 md:tw-min-w-[280px]':
                  useTailwindPrefix,
               'md:max-w-full': vertical && !useTailwindPrefix,
               'md:tw-max-w-full': vertical && useTailwindPrefix,
            })}
         >
            <div
               className={classNames({
                  'md:hidden flex justify-between items-center px-4 border-b border-gray-200 h-[50px]':
                     !useTailwindPrefix,
                  'md:tw-hidden tw-flex tw-justify-between tw-items-center tw-px-4 tw-border-b tw-border-gray-200 tw-h-[50px]':
                     useTailwindPrefix,
               })}
            >
               <div className={classNames({ 'text-3xl': !useTailwindPrefix, 'tw-text-3xl': useTailwindPrefix })}>
                  {intl.formatMessage({ id: 'booker.origin' })}
               </div>
               <a
                  onClick={closeSelector}
                  className={classNames({
                     'cursor-pointer text-2xl': !useTailwindPrefix,
                     'tw-cursor-pointer tw-text-2xl': useTailwindPrefix,
                  })}
               >
                  <FontAwesomeIcon icon={faTimes} />
               </a>
            </div>
            <div
               className={classNames({ 'p-4 md:hidden': !useTailwindPrefix, 'tw-p-4 md:tw-hidden': useTailwindPrefix })}
            >
               <div
                  className={classNames({
                     'border rounded w-full px-2 flex items-center space-x-2': !useTailwindPrefix,
                     'tw-border tw-rounded tw-w-full tw-px-2 tw-flex tw-items-center tw-space-x-2': useTailwindPrefix,
                  })}
               >
                  <input
                     className={classNames({
                        'py-2 outline-none w-full': !useTailwindPrefix,
                        'tw-py-2 tw-outline-none tw-w-full': useTailwindPrefix,
                     })}
                     onChange={onInputChange}
                     value={text}
                     type={'text'}
                     placeholder={intl.formatMessage({ id: 'booker.origin' })}
                     onKeyUp={onInputKeyUp}
                  />
                  {text?.length > 0 && <FontAwesomeIcon icon={faTimes} onClick={clearText} color="#82888a" />}
               </div>
            </div>
            {origins.length > 0 && origins.map(renderOptions)}
         </OverlayTrigger.Overlay>
      </OverlayTrigger>
   )
}

export default OriginField
