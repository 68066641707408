import { useCallback, useState, useEffect, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from '../store/hooks'
import { Booker, ChangeRatesOnClickType, Destination, NavBar, service } from 'booker'
import {
   bookerPlateToHotelType,
   getDates,
   getForms,
   getSelectedDestination,
   getSelectedForm,
   getSiteUrl,
   isVertical,
} from '../utils/siteConfigUtils'
import qs from 'qs'
import { SiteConfig } from '../api/siteConfig'
import { HotelBookerRatesThunkRequest, getHotelBookerRatesThunk } from '../features/hotelBookerRatesSlice'
import PromotionModal from './PromotionModal'
import { clear, getAddOnListThunk } from '../features/addOnListSlice'
import { clear as clearQuoteList, quoteAddOnListThunk } from '../features/quoteAddOnListSlice'
import { AddOnListRequest } from '../api/addOnList'
import { QuoteAddOnListRequest } from '../api/quoteAddOnList'
import { createTripThunk } from '../features/tripSlice'
import { getExtraParamsFromQuery } from 'booker/src/utils/bookerUtils'

export type HotelType = {
   id: number
   name: string
   place: {
      id: number
      name: string
   }
   destination: {
      id: number
      name: string
      airportCode: string
   }
   chain?: {
      id: number
      name: string
   }
}

type Props = {
   siteConfig: SiteConfig
   dataOnlyFormToShow: 'HOTEL_FLIGHT' | 'HOTEL' | 'SERVICES' | null
   hotelId?: number
   hotelList?: Array<HotelType>
}

type quoteCalendarRatesProps = {
   range?: { startDate?: string; endDate?: string }
   adults: number
   children: number
   destination: Destination
}

const BookerWrapper = ({ siteConfig, dataOnlyFormToShow, hotelList, hotelId }: Props) => {
   const completeHotelList = hotelList || bookerPlateToHotelType(siteConfig.bookerPlaces, siteConfig.externalHotels)
   const forms = getForms(
      siteConfig,
      completeHotelList.map((c) => c.id),
      dataOnlyFormToShow,
   )
   const showStandaloneServices = dataOnlyFormToShow === 'SERVICES' ? true : siteConfig.showStandaloneServices
   const [selectedForm, setSelectedForm] = useState<service>(
      getSelectedForm({
         ...forms,
         showStandaloneServices,
      }),
   )
   const [promotionCode, setPromotionCode] = useState<string>()
   const dispatch = useAppDispatch()
   const hotelBookerRates = useAppSelector((state) => state.hotelBookerRates)
   const siteUrl = getSiteUrl(siteConfig)
   const addOnListWrapper = useAppSelector((state) => state.addOnListWrapper)
   const quoteAddOnListWrapper = useAppSelector((state) => state.quoteAddOnListWrapper)

   const quoteHotelBookerRates = useCallback(
      ({ range, adults, children, destination }: quoteCalendarRatesProps) => {
         const request: HotelBookerRatesThunkRequest = {
            range,
            adults,
            children,
            destination,
         }
         dispatch(getHotelBookerRatesThunk(request))
      },
      [dispatch],
   )

   ///hotelId ? { destinationType: 'HOTEL', id: hotelId } : siteConfig.hotelForm?.destination,
   const initialDestination = useMemo(() => {
      return getSelectedDestination(siteConfig.bookerPlaces, siteConfig.hotelForm?.destination)
   }, [siteConfig.bookerPlaces, siteConfig.hotelForm?.destination])

   const initialDates = useMemo(() => {
      return getDates({
         startDate: siteConfig.hotelForm?.startDate,
         endDate: siteConfig.hotelForm?.endDate,
      })
   }, [siteConfig.hotelForm?.startDate, siteConfig.hotelForm?.endDate])

   useEffect(() => {
      if (initialDestination && siteConfig.hotelForm?.quoteRates) {
         quoteHotelBookerRates({
            range: initialDates,
            adults: 2,
            children: 0,
            destination: initialDestination,
         })
      }
   }, [initialDates, initialDestination, quoteHotelBookerRates, siteConfig.hotelForm?.quoteRates])

   function onHotelFlightSubmit(data: any) {
      const result = {
         departureAirportCode: data.origin.code,
         ...(data.destination.destinationType === 'destination'
            ? {
                 destinationId: data.destination.id,
                 arrivalAirportCode: data.destination.airportCode,
              }
            : {
                 hotelId: data.destination.id,
                 arrivalAirportCode: data.destination.airportCode,
              }),
         startDate: data.tripDates.startDate,
         endDate: data.tripDates.endDate,
         rooms: data.rooms,
         ...(data.promotionCode && { pc: data.promotionCode }),
      }
      let extras: Record<string, any> | null = null
      try {
         if (window.location.search) {
            const params = qs.parse(window.location.search, { ignoreQueryPrefix: true })
            extras = getExtraParamsFromQuery(params)
         }
      } catch (e) {}
      const query = qs.stringify(
         { ...result, ...extras },
         { arrayFormat: 'indices', allowDots: true, encodeValuesOnly: true },
      )
      if (data.destination.destinationType === 'destination') {
         window.location.href = `${siteUrl}/hotel-flight/hotel?${query}`
      } else if (data.destination.destinationType === 'hotel') {
         window.location.href = `${siteUrl}/hotel-flight/room?${query}`
      }
   }

   const onChangeRates = (data: ChangeRatesOnClickType) => {
      quoteHotelBookerRates(data)
   }

   function onHotelSubmit(data: any, submitUrl?: string) {
      if (submitUrl) {
         window.location.href = `${submitUrl}`
         return
      }
      const result = {
         checkIn: data.tripDates.startDate,
         checkOut: data.tripDates.endDate,
         rooms: data.rooms,
         ...(data.promotionCode && { pc: data.promotionCode }),
         ...(data.flexible && { flexible: data.flexible }),
      }
      let extras: Record<string, any> | null = null
      try {
         const params = qs.parse(window.location.search, { ignoreQueryPrefix: true })
         extras = getExtraParamsFromQuery(params)
      } catch (e) {}
      const query = qs.stringify(
         { ...result, ...extras },
         { arrayFormat: 'indices', allowDots: true, encodeValuesOnly: true },
      )
      if (data.destination.destinationType === 'hotel') {
         // dispatch(setHotelQuoteListIsFetching(true))
         if (completeHotelList.length === 1) {
            window.location.href = `${siteUrl}/rooms?${query}`
         } else {
            window.location.href = `${siteUrl}/${data.destination.id}/rooms?${query}`
         }
      } else if (data.destination.destinationType === 'destination') {
         window.location.href = `${siteUrl}/hotels/${data.destination.id}?${query}`
      }
   }

   async function onServicesSubmit(request: any, callback: Function) {
      const trip = await dispatch(createTripThunk(request)).unwrap()
      const query = qs.stringify({ t: trip.uuid })
      const url = `${siteUrl}/trip-details?${query}`
      if (siteConfig.submitOnSameTab) {
         window.location.href = url
      } else {
         window.open(url, '_blank')
      }
      if (callback) {
         callback()
      }
   }

   function applyPromo(promo: string) {
      setPromotionCode(promo)
   }

   function drawNavBar() {
      const types = []
      if (forms.hotelForm) {
         types.push(<NavBar.HotelItem />)
      }
      if (forms.hotelFlightForm) {
         types.push(<NavBar.HotelFlightItem />)
      }
      if (forms.serviceForm && showStandaloneServices) {
         types.push(<NavBar.ServiceItem />)
      }
      return (
         (types.length > 1 || (siteConfig.externalServices && siteConfig.externalServices.length > 0)) && (
            <NavBar selected={selectedForm} onChange={setSelectedForm} useTailwindPrefix selectedClassName="active">
               {types.map((type) => type)}
               {siteConfig.externalServices?.map((service, index) => (
                  <NavBar.Item key={index} url={service.url} icon={service.icon}>
                     {service.displayText}
                  </NavBar.Item>
               ))}
            </NavBar>
         )
      )
   }
   const color = siteConfig.theme?.calendarColor || siteConfig.theme?.primaryColor

   return (
      <>
         <Booker
            locale={siteConfig.language}
            dateFormat={siteConfig.dateFormat}
            hideIcon={!siteConfig.showCalendarIcon}
            isAutocomplete={false}
            vertical={isVertical()}
            originFetchUrl={process.env.REACT_APP_PUBLIC_AIRPORTS}
            useTailwindPrefix
            primaryColor={color}
            inline={forms.hotelForm?.promoCodeInline}
            promoCodeInlineIcon={forms.hotelForm?.promoCodeInlineIcon}
         >
            {drawNavBar()}
            {forms.hotelForm && selectedForm === 'hotel' && (
               <Booker.Hotel
                  hotelForm={forms.hotelForm}
                  hotelList={completeHotelList}
                  onSubmit={onHotelSubmit}
                  request={{
                     tripDates: initialDates,
                     destination: initialDestination,
                     promotionCode,
                  }}
                  rates={hotelBookerRates}
                  onChangeRates={onChangeRates}
               />
            )}
            {forms.hotelFlightForm && selectedForm === 'hotelFlight' && (
               <Booker.HotelFlight
                  hotelFlightForm={forms.hotelFlightForm}
                  hotelList={completeHotelList}
                  onSubmit={onHotelFlightSubmit}
                  request={{
                     tripDates: getDates(siteConfig.hotelFlightForm),
                     destination: getSelectedDestination(siteConfig.bookerPlaces, forms.hotelFlightForm.destination),
                     promotionCode,
                  }}
               />
            )}
            {forms.serviceForm && selectedForm === 'service' && showStandaloneServices && (
               <Booker.Services
                  serviceForm={forms.serviceForm}
                  hotelList={[]}
                  onSubmit={onServicesSubmit}
                  getAddOnList={(request: AddOnListRequest) => dispatch(getAddOnListThunk(request))}
                  clearAddOnList={() => dispatch(clear())}
                  addOnListWrapper={addOnListWrapper}
                  quoteListWrapper={quoteAddOnListWrapper}
                  clearQuoteList={() => dispatch(clearQuoteList())}
                  onQuote={(request: QuoteAddOnListRequest) => dispatch(quoteAddOnListThunk(request))}
               />
            )}
         </Booker>
         {forms.hotelForm && selectedForm === 'hotel' && <PromotionModal hotelId={hotelId} onApplyPromo={applyPromo} />}
      </>
   )
}

export default BookerWrapper
